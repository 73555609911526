import React, { Component, Fragment } from 'react';
import { autobind } from 'core-decorators';
import { decode } from 'base64-url';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { TextInput } from '@rexlabs/text-input';
import { createValidationRules } from '@rexlabs/validator';
import { Link, parseUrlToRoute, push } from '@rexlabs/whereabouts';
import { Body, Heading, Link as TextLink } from 'view/components/text';
import EmailFixture from 'view/components/email-fixture';
import AuthContext from 'view/containers/auth-context';
import { BREAKPOINTS, COLORS, FONT, KEYFRAMES, PADDINGS } from 'src/theme';
import ROUTES from 'src/routes';

// Shared
import { Select } from 'shared/components/input/select';
import Checkbox from 'shared/components/input/checkbox';

import PasswordInput from 'shared/components/input/password';
import Spinner from 'shared/components/spinner';
import {
  DefaultButton,
  LoadingButton,
  TextButton
} from 'shared/components/button';
import { Form, FormField, ReactForms } from 'shared/components/form';
import { ICONS } from 'shared/components/icon';
import _ from 'lodash';
import { TooltipStateful } from '@rexlabs/tooltip';
import config from 'shared/utils/config';

const validate = createValidationRules({
  email: 'required|email',
  password: 'required'
});

const fieldOverrides = {
  InnerField: StyleSheet({
    field: {
      // HACK: this is just to increase specificity to fix
      // styling issues
      '&&': {
        marginTop: '0'
      }
    }
  })
};

const defaultStyles = StyleSheet({
  wrapPassword: {
    position: 'relative'
  },

  transition: {
    transition: 'opacity 100ms ease-out'
  },
  button: {
    [BREAKPOINTS.MOBILE]: {
      width: '100%'
    }
  },
  rememberMe: {
    [BREAKPOINTS.MOBILE]: {
      marginTop: '3rem',
      marginBottom: '2rem',
      textAlign: 'center'
    }
  },
  isSubmitting: {
    opacity: '0.3'
  },
  responsiveLabel: {
    [BREAKPOINTS.MOBILE]: {
      fontSize: '14px',
      marginBottom: '0.85rem'
    },
    color: COLORS.GREY,
    fontSize: '16px',
    lineHeight: '16px',
    marginBottom: '1rem'
  },
  deprecatedLogin: {
    paddingTop: PADDINGS.L,
    '& p': {
      marginTop: PADDINGS.S
    }
  }
});

const rosieIconStyles = StyleSheet({
  icon: {
    position: 'absolute',
    left: '22px',
    height: '26px',
    width: '22px'
  }
});

@styled(rosieIconStyles)
@autobind
class RosieIcon extends Component {
  render() {
    const { styles: s } = this.props;

    return <img {...s('icon')} src={ICONS.ROSIE} alt={'rosie-icon'} />;
  }
}

const tetherStyles = StyleSheet({
  opening: {
    animation: `0.3s ${KEYFRAMES.FADE_IN}`
  },
  closing: {
    animation: `0.3s ${KEYFRAMES.FADE_OUT}`
  }
});

@styled(
  StyleSheet({
    hint: {
      marginLeft: '15px'
    },
    coachMark: {
      borderRadius: '50%',
      color: COLORS.WHITE,
      backgroundColor: COLORS.LOGIN.STATES.HOVER,
      opacity: 0.5,
      width: '20px',
      height: '20px',
      paddingTop: '1px',
      paddingLeft: '1px',
      lineHeight: '100%',
      '&:hover': {
        opacity: 1,
        backgroundColor: COLORS.DARK_GREY
      }
    }
  })
)
@autobind
class HintTooltip extends Component {
  render() {
    const { styles: s } = this.props;
    return (
      <Box {...s('hint')}>
        <TooltipStateful
          tetherStyles={tetherStyles}
          placement='top-start'
          openOn='HOVER'
          distance={'4px'}
          closeOn='HOVER'
          Content={() => (
            <Box>
              <Box pb='10px'>
                <Body white>
                  {`Checking 'Remember Me' reduces the number of times you're
                  asked to log in.`}
                </Body>
              </Box>
              <Box>
                <Body white>
                  To keep your account secure, only use this option on your
                  personal device.
                </Body>
              </Box>
            </Box>
          )}
        >
          <Box justifyContent='center' alignItems='center' {...s('coachMark')}>
            <Body large bold white>
              ?
            </Body>
          </Box>
        </TooltipStateful>
      </Box>
    );
  }
}

@styled(defaultStyles)
@autobind
class Login extends Component {
  static contextType = AuthContext;

  redirectToAuthenticationServiceInProduction() {
    const host = window.location.host;

    if (this.isOldPocket()) return;

    if (host.startsWith('alpha-auth-app') || host.startsWith('auth-app')) {
      window.location.href = config.AUTHENTICATION_SERVICE_FRONTEND_URL;
    }
  }

  isModernRexCRMMobile() {
    return navigator.userAgent.includes('rex_crm_mobile');
  }

  isOldPocket() {
    return !this.isModernRexCRMMobile() && this.isWebView();
  }

  isWebView() {
    const rules = [
      // if it says it's a webview, let's go with that
      'WebView',
      // iOS webview will be the same as safari but missing "Safari"
      '(iPhone|iPod|iPad)(?!.*Safari)',
      // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
      // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
      'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})',
      // old chrome android webview agent
      'Linux; U; Android'
    ];

    const regex = new RegExp('(' + rules.join('|') + ')', 'ig');

    return 'ReactNativeWebView' in window || !!navigator.userAgent.match(regex);
  }

  componentDidMount() {
    const {
      loginInfo: { loginPreference, accounts },
      handleLogin,
      handleAccountLogin,
      handleRememberMeChange,
      app
    } = this.context;
    const {
      whereabouts: {
        query: { code, state }
      }
    } = this.props;

    this.redirectToAuthenticationServiceInProduction();

    if (app === 'pocket') {
      handleRememberMeChange();
    }

    if (code && state) {
      const route = parseUrlToRoute(window.location.href);
      const stateSplit = state.split('.');

      route.query = {
        ...route.query,
        state: stateSplit[0],
        ..._.reduce(
          stateSplit,
          (acc, section, index) => {
            if (index === 0) return acc;
            const query = decode(section).split('=');
            acc[query[0]] = query[1];
            return acc;
          },
          {}
        )
      };
      route.path = window.location.pathname;

      push({ config: route });

      handleLogin(loginPreference, { code, state: stateSplit[0] });
    }
    // For reset password - we redirect here to handle account selection.
    // If we already have a prefered account on mount, we want to skip that and
    // go to Rex.
    // This is simple enough that - even though it's duplicated from componentDidUpdate
    // it probably doesn't need to be in its own function.
    if (accounts) {
      const filteredAccounts = this.filterAccounts(accounts);
      const singleAccount = this.isRedirecting(filteredAccounts);
      if (singleAccount) {
        handleAccountLogin(singleAccount);
      }
    }
  }

  componentDidUpdate() {
    const {
      loginInfo: { accounts },
      handleAccountLogin
    } = this.context;

    if (accounts) {
      const filteredAccounts = this.filterAccounts(accounts);
      const singleAccount = this.isRedirecting(filteredAccounts);
      if (singleAccount) {
        handleAccountLogin(singleAccount);
      }
    }
  }

  isValidApp(appId) {
    return ['rex', 'wingsadmin', 'rexgroup', 'pocket'].includes(appId);
  }

  filterAccounts(accounts) {
    const {
      whereabouts: { query }
    } = this.props;

    const appId = this.isValidApp(_.get(query, 'app_id'))
      ? _.get(query, 'app_id')
      : 'rex';

    // Note that Pocket & Rex share the same accounts
    return accounts
      .filter(
        (account) =>
          account.account_status === 'active' &&
          (account.app_id === appId ||
            (account.app_id === 'rex' && appId === 'pocket'))
      )
      .sort((a, b) => (a.account_name < b.account_name ? -1 : 1));
  }

  preventDefault(e) {
    e.preventDefault();
  }

  getLastAccount(filteredAccounts) {
    const {
      loginInfo: { email },
      lastAccount
    } = this.context;

    const {
      whereabouts: { query }
    } = this.props;

    const appId = _.get(query, 'app_id') || 'rex';
    const accountId = _.get(lastAccount, [email, appId]);

    if (accountId) {
      const findAccount = filteredAccounts.find(
        // ids can be strings or numbers
        (a) => Number(a.account_id) === Number(accountId)
      );
      if (findAccount) {
        return findAccount;
      }
    }
  }

  // If you want to show the account select every time,
  // add a query parameter with `select_account=true`

  isRedirecting(filteredAccounts) {
    const {
      whereabouts: { query }
    } = this.props;
    // queries provide strings
    const alwaysChooseAccount = _.get(query, 'select_account') === 'true';

    if (filteredAccounts) {
      if (filteredAccounts.length === 1) {
        return filteredAccounts[0];
      } else {
        if (alwaysChooseAccount) {
          return false;
        }
        return this.getLastAccount(filteredAccounts);
      }
    }
  }

  getLoginHeading(appName) {
    const {
      loginInfo: { email, accounts, firstName }
    } = this.context;
    const filteredAccounts = accounts && this.filterAccounts(accounts);

    if (filteredAccounts) {
      return 'Select an account';
    }
    const messages = {
      pocket: {
        beforeEmail: 'Log in',
        afterEmail: 'Welcome'
      },
      otherApp: {
        beforeEmail: `Log in to ${appName}`,
        afterEmail: `Welcome ${firstName ? `, ${firstName}` : 'back'}`
      }
    };

    const app = appName === 'Pocket' ? 'pocket' : 'otherApp';
    const loginStage = email ? 'afterEmail' : 'beforeEmail';

    return messages[app][loginStage];
  }

  getInitialValues() {
    const {
      meta: { remember },
      loginInfo: { email, accounts }
    } = this.context;
    const filteredAccounts = accounts && this.filterAccounts(accounts);
    const defaultAccount =
      filteredAccounts && this.getLastAccount(filteredAccounts);
    return {
      email,
      remember,
      account: defaultAccount
        ? { label: defaultAccount.account_name, value: defaultAccount }
        : ''
    };
  }

  render() {
    const {
      app,
      meta: { loading },
      loginInfo: {
        email,
        firstName,
        lastName,
        loginMethods,
        loginPreference,
        accounts
      },
      handleRemoveEmail,
      handleOtherLoginMethods,
      handleChoosePassword,
      handleRememberMeChange,
      handleContinueAndPasswordSubmit
    } = this.context;

    const {
      styles: s,
      whereabouts: { query }
    } = this.props;

    let appName = 'Rex';
    switch (app) {
      case 'rexgroup':
        appName = 'Rex Group App';
        break;
      case 'pocket':
        appName = 'Pocket';
        break;
    }

    const filteredAccounts = accounts && this.filterAccounts(accounts);
    const availableApps =
      accounts &&
      _.uniq(
        accounts
          .filter((account) => account.account_status === 'active')
          .map((account) => account.app_id)
      ).map((appId) => {
        switch (appId) {
          case 'wingsadmin':
            return { name: 'Wings Admin', appId };
          case 'rexgroup':
            return { name: 'Rex Group App', appId };
          case 'pocket':
            return { name: 'Pocket', appId };
          default:
            return { name: 'Rex', appId: 'rex' };
        }
      });

    if (this.isOldPocket()) {
      return (
        <Box {...s('deprecatedLogin')}>
          <Heading>
            Logging in with this version of Pocket is no longer supported.
          </Heading>
          <Body medium darkergrey normal regular>
            Please download the latest version of Pocket from the app store.
          </Body>
        </Box>
      );
    }

    return (
      <Box>
        <ReactForms
          validateOnMount
          touchOnMount={false}
          touchOnChange={false}
          initialValues={this.getInitialValues()}
          validate={validate}
          handleSubmit={handleContinueAndPasswordSubmit}
        >
          {({ isValid, isSubmitting, submitForm }) => {
            const hasName = !!firstName && !!lastName;
            if (this.isRedirecting(filteredAccounts)) {
              return (
                <Box
                  alignItems='center'
                  justifyContent='center'
                  height='28rem'
                  width='100%'
                  p={PADDINGS.M}
                >
                  <Spinner large dark />
                </Box>
              );
            }

            return (
              <Fragment>
                <Box
                  {...s('transition', {
                    isSubmitting: isSubmitting && !email
                  })}
                  width='100%'
                  mt={PADDINGS.XL}
                  mb={
                    availableApps || app === 'popper'
                      ? PADDINGS.XL
                      : PADDINGS['3XL']
                  }
                >
                  <Heading level={1}>{this.getLoginHeading(appName)}</Heading>
                </Box>
                <Form
                  name='login'
                  showErrors={false}
                  onSubmit={this.preventDefault}
                >
                  {filteredAccounts ? (
                    filteredAccounts.length > 1 ? (
                      <Fragment>
                        <FormField
                          sendImmediate
                          name='account'
                          Input={Select}
                          inputProps={{
                            shouldOpenOnFocus: true,
                            isSearchable: filteredAccounts.length > 3,
                            valueAsObject: true,
                            options: filteredAccounts.map((account) => ({
                              value: account,
                              label: account.account_name
                            })),
                            placeholder: 'Select user account',
                            pluckValue: (option) => option.value.account_id
                          }}
                        />
                        <Box
                          flexDirection='row'
                          justifyContent='flex-end'
                          mt={PADDINGS.XL}
                        >
                          <LoadingButton
                            {...s('button')}
                            softblue={query.app_id !== 'pocket'}
                            pocket={query.app_id === 'pocket'}
                            large
                            form='login'
                            isLoading={isSubmitting}
                            isDisabled={!isValid}
                            onClick={submitForm}
                          >
                            Log in
                          </LoadingButton>
                        </Box>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Box width='100%' mb={PADDINGS.XL}>
                          <Body medium darkergrey normal regular>
                            {availableApps.length > 0 ? (
                              <span>
                                No active accounts for{' '}
                                <span
                                  style={{ fontWeight: FONT.WEIGHTS.SEMIBOLD }}
                                >
                                  {appName}
                                </span>
                                , but you do have active accounts for:
                              </span>
                            ) : (
                              'You have no active accounts'
                            )}
                          </Body>
                        </Box>
                        {availableApps.length > 0 ? (
                          availableApps.map((app) => (
                            <Box
                              key={app.appId}
                              flexDirection='column'
                              width='100%'
                              mt={PADDINGS.XS}
                            >
                              <LoadingButton
                                {...s('button')}
                                softblue={query.app_id !== 'pocket'}
                                pocket={query.app_id === 'pocket'}
                                large
                                onClick={() => {
                                  const route = parseUrlToRoute(
                                    window.location.href
                                  );
                                  route.query = {
                                    app_id: app.appId
                                  };
                                  route.path = window.location.pathname;
                                  push({ config: route });
                                }}
                              >
                                {app.name}
                              </LoadingButton>
                            </Box>
                          ))
                        ) : (
                          <Box
                            flexDirection='column'
                            width='100%'
                            mt={PADDINGS.XS}
                          >
                            <Link to={ROUTES.LOGIN} query={{ app_id: app }}>
                              {({ onClick }) => {
                                // HACK: we are already on the LOGIN route's component, there is probably a way to refresh the component quicker than reloading?
                                onClick = () => {
                                  window.location.reload(true);
                                };
                                return (
                                  <DefaultButton
                                    softblue={query.app_id !== 'pocket'}
                                    pocket={query.app_id === 'pocket'}
                                    large
                                    onClick={onClick}
                                  >
                                    Return to login
                                  </DefaultButton>
                                );
                              }}
                            </Link>
                          </Box>
                        )}
                      </Fragment>
                    )
                  ) : email ? (
                    <Fragment>
                      <EmailFixture
                        email={email}
                        initials={
                          hasName
                            ? `${_.first(firstName)}${_.first(lastName)}`
                            : _.first(email)
                        }
                        isPocket={query.app_id === 'pocket'}
                        onCloseClick={handleRemoveEmail}
                      />
                      {(loginMethods.length === 1 &&
                        loginMethods[0].id === 'password') ||
                      loginPreference === 'password' ? (
                        <Box
                          flexDirection='column'
                          width='100%'
                          mt={PADDINGS['3XL']}
                        >
                          <Box
                            justifyContent='space-between'
                            {...s('forgotPassword')}
                            alignItems='flex-end'
                          >
                            <span {...s('responsiveLabel')}>password</span>
                            <Link
                              to={ROUTES.RESET_PASSWORD}
                              query={{ app_id: app }}
                            >
                              {({ onClick, target }) => (
                                <Box mb='1rem'>
                                  <TextLink
                                    small
                                    grey
                                    href={target}
                                    onClick={onClick}
                                  >
                                    Forgotten your password?
                                  </TextLink>
                                </Box>
                              )}
                            </Link>
                          </Box>
                          <Box {...s('wrapPassword')}>
                            <input
                              style={{
                                position: 'absolute',
                                top: '-9999px',
                                left: '-9999px',
                                clip: 'rect(0px, 0px, 0px, 0px)'
                              }}
                              value={email}
                              name='email'
                              autoComplete='email'
                            />
                            <StylesProvider components={fieldOverrides}>
                              <FormField
                                sendImmediate
                                name='password'
                                Input={PasswordInput}
                                inputProps={{
                                  placeholder: 'Enter your password',
                                  autoFocus: true
                                }}
                              />
                            </StylesProvider>

                            <Box
                              flexDirection='row-reverse'
                              justifyContent='start'
                              mt={PADDINGS.XL}
                              flexWrap='wrap'
                            >
                              <LoadingButton
                                {...s('button')}
                                softblue={query.app_id !== 'pocket'}
                                pocket={query.app_id === 'pocket'}
                                large
                                form='login'
                                isLoading={isSubmitting}
                                isDisabled={!isValid}
                                onClick={submitForm}
                              >
                                Log in
                              </LoadingButton>
                              {query.app_id !== 'pocket' && (
                                <Box
                                  {...s('transition', 'rememberMe', {
                                    isSubmitting
                                  })}
                                  flex={1}
                                  alignItems='start'
                                >
                                  <FormField
                                    {...s('checkbox')}
                                    name='remember'
                                    Input={Checkbox}
                                    inputProps={{
                                      label: 'Remember me',
                                      suffix: <HintTooltip />
                                    }}
                                    onChange={handleRememberMeChange}
                                  />
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          flexDirection='column'
                          width='100%'
                          mt={PADDINGS['3XL']}
                        >
                          {loginMethods.map(
                            (method, index) =>
                              method.id !== 'password' && (
                                <Box
                                  key={method.id}
                                  flexDirection='column'
                                  mt={index !== 1 && PADDINGS.XS}
                                >
                                  <LoadingButton
                                    {...s('button')}
                                    softblue={query.app_id !== 'pocket'}
                                    pocket={query.app_id === 'pocket'}
                                    large
                                    form='login'
                                    isLoading={
                                      loginPreference === method.id && loading
                                    }
                                    onClick={() => {
                                      handleOtherLoginMethods(method);
                                    }}
                                  >
                                    {method.id === 'spicer-haart-ad' && (
                                      <RosieIcon />
                                    )}
                                    Log in via {method.label}
                                  </LoadingButton>
                                </Box>
                              )
                          )}
                          {/* This will be always last so it is added here */}
                          {loginMethods.find(
                            (method) => method.id === 'password'
                          ) && (
                            <Box
                              flexDirection='column'
                              width='100%'
                              mt={PADDINGS.XS}
                            >
                              <TextButton
                                grey
                                large
                                form='login'
                                onClick={handleChoosePassword}
                              >
                                Use password instead
                              </TextButton>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Box {...s('transition', { isSubmitting })} width='100%'>
                        <div {...s('responsiveLabel')}>email address</div>
                        <StylesProvider components={fieldOverrides}>
                          <FormField
                            sendImmediate
                            shouldUnregister={false}
                            name='email'
                            Input={TextInput}
                            inputProps={{
                              type: 'email',
                              placeholder: 'rexuser@email.com'
                            }}
                            onBlur={(e) => {
                              if (window.bugsnagClient) {
                                window.bugsnagClient.user = {
                                  email: e.target.value
                                };
                              }
                            }}
                          />
                        </StylesProvider>
                        <input
                          style={{
                            position: 'absolute',
                            top: '-9999px',
                            left: '-9999px',
                            clip: 'rect(0px, 0px, 0px, 0px)'
                          }}
                          type='password'
                          name='password'
                          autoComplete='password'
                        />
                      </Box>
                      <Box
                        alignItems='center'
                        width='100%'
                        mt={PADDINGS.XL}
                        flexDirection='row-reverse'
                        justifyContent='start'
                        flexWrap='wrap'
                      >
                        <LoadingButton
                          {...s('button')}
                          softblue={query.app_id !== 'pocket'}
                          pocket={query.app_id === 'pocket'}
                          large
                          form='login'
                          isLoading={isSubmitting}
                          isDisabled={!isValid}
                          onClick={submitForm}
                        >
                          Continue
                        </LoadingButton>
                        {query.app_id !== 'pocket' && (
                          <Box
                            {...s('transition', 'rememberMe', { isSubmitting })}
                            flex={1}
                          >
                            <FormField
                              name='remember'
                              Input={Checkbox}
                              inputProps={{
                                label: 'Remember me',
                                suffix: <HintTooltip />
                              }}
                              onChange={handleRememberMeChange}
                            />
                          </Box>
                        )}
                      </Box>
                    </Fragment>
                  )}
                </Form>
              </Fragment>
            );
          }}
        </ReactForms>
      </Box>
    );
  }
}

export default Login;
