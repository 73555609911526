module.exports = {
  // BE API base url
  API_URL: 'https://alpha-api.rexsoftware.com/v1/global',

  // FE app urls
  REX_APP_URL: 'https://alpha-app.rexsoftware.com',
  AUTH_APP_URL: 'https://alpha-auth-app.rexsoftware.com',
  GROUP_APP_URL: 'https://alpha-group-app.rexsoftware.com',
  GHECK_IN_APP_URL: 'https://alpha-check-in-app.rexsoftware.com',

  AUTHENTICATION_SERVICE_API_URL: 'https://auth.rexsoftware.com/api/v1',
  AUTHENTICATION_SERVICE_FRONTEND_URL: 'https://auth.rexsoftware.com',

  // Feature flags
  ENABLE_REX_FRAME: true,
  ENABLE_GA_DEBUG: false,
  ENABLE_APPCUES: false,
  ENABLE_NAV_BLOCKING: false,
  ENABLE_SCHEDULED_MESSAGES: true,
  ENABLE_PROSPECTOR: true,

  ENABLE_NEW_RELIC: true,

  // Intercom & Zendesk Chat
  INTERCOM_CHAT_ENABLED_UK: true,
  INTERCOM_CHAT_WHITELIST: [],
  ZENDESK_CHAT_WHITELIST: [],

  // Bugsnag
  SHELL_BUGSNAG_ID: '57db801e47b58636b221b2cb2eb24fb1',
  CLASSIC_BUGSNAG_ID: 'ca9f942120c85e59edc6f176122f43fb',
  AUTH_APP_BUGSNAG_ID: '9f9e6423bdc3410c58c038b7a25b5447',
  GROUP_APP_BUGSNAG_ID: '532d2dcd846b67ee535744c47be7e2ca',
  CHECK_IN_APP_BUGSNAG_ID: 'b6dabc951e5607d82b3e2712ccf4228e',

  // Misc
  COOKIE_URL: 'rexsoftware.com',
  MAPBOX_TOKEN:
    'pk.eyJ1IjoicmV4c29mdHdhcmUiLCJhIjoiYXloQWNDOCJ9.VLh5gCu-smMd2x8DibPi7g',
  INTERCOM_ID: 'aqznm9em',
  SEGMENT_ID: 'lmpTsat8Hqm1dPcfTW0qoeB3cVBHemJq',
  USERLEAP_ID: 'nnsDSgrtfg',
  FLAGSMITH_ID: 'UZri8eAHJpkW4huLRsQfUe',
  FULLVIEW_ID: '0a6d0ab6-39ab-41b7-955b-15abc69670ed',
  ENVIRONMENT: 'Alpha',
  // NOTE: AG Grid key expires 3_May_2023. Update before then!
  AG_GRID_KEY:
    'CompanyName=Rex Software,LicensedApplication=Rex CRM,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-027746,ExpiryDate=3_May_2023_[v2]_MTY4MzA2ODQwMDAwMA==41b29f2135b58dd313fac291f0944a73',

  RELEASE: {
    STAGE: 'alpha',
    VERSION: '718.0.1',
    HASH: '70b97cba7188550ba286d53117af38b6b57c23dd',
    IMAGE: 'develop-70b97cba7188550ba286d53117af38b6b57c23dd'
  },

  // REPORT TEMPLATE
  REPORT_TYPEKIT: 'vfu5nuq'
};
